import React, { createContext, useState } from "react"

export const StockContext = createContext()

export const StockProvider = ({ children }) => {
  const [stock, setStock] = useState({ bsde: 0, duti: 0, a26: 0 })

  return (
    <StockContext.Provider value={{ stock, setStock }}>
      {children}
    </StockContext.Provider>
  )
}
