import React, { createContext, useState } from "react"

export const PopupContext = createContext()

export const PopupProvider = ({ children }) => {
  const [popupBanner, setPopup] = useState(true)
  return (
    <PopupContext.Provider value={{ popupBanner, setPopup }}>
      {children}
    </PopupContext.Provider>
  )
}
