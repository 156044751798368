import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"

import { client } from "./src/apollo/client"
import { StockProvider } from "./src/context/stockContext"
import { PopupProvider } from "./src/context/popupContext"
import { LangProvider } from "./src/context/langContext"
import { AuthProvider } from "./src/context/authContext"

// import "./src/assets/scss/main.scss"
// import "@glidejs/glide/dist/css/glide.core.min.css"
// import "@glidejs/glide/dist/css/glide.theme.min.css"
// import "bootstrap/dist/js/bootstrap.bundle"

// export { wrapRootElement } from "./src/apollo/wrap-root-element"
export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <LangProvider>
        <PopupProvider>
          <AuthProvider>
            <StockProvider>{element}</StockProvider>
          </AuthProvider>
        </PopupProvider>
      </LangProvider>
    </ApolloProvider>
  )
}
